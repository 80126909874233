import React, { useEffect, useState, }  from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { socket } from '../../helpers/socket';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft, faTimes, faRepeat } from '@fortawesome/free-solid-svg-icons';
import { OptionsModal } from '../layout/Modal/OptionsModal';
import { EndRoundModal } from '../layout/Modal/EndRoundModal';
import { useOnlineStatus } from '../../helpers/hooks/useOnlineStatus';


import './round.scss';
import Players from './Players';
import {PlayerName}  from '../ui/PlayerName';
import {PlayerScoreTotal}  from '../ui/PlayerScoreTotal';

import { getExistingRound, setPlayerIncScore, setPlayerDecScore, setPlayerClearScore } from '../../store/reducers/roundReducer';
import { setHistory } from '../../store/reducers/historyReducer';
import { Header } from '../layout/Header';

const Round = () => {

  const dispatch = useDispatch();
  const { roundName } = useParams();

  const cardStore = useSelector(state => state.card);
  const { players, id } = cardStore;

  const roundStore = useSelector(state => state.round);
  const { holes, isRoundLoading, roundEnded } = roundStore;

  const [chunkIndex, setChunkIndex] = useState(0);  

  const [ isControlsVisible, setIsControlsVisible ] = useState(false);
  
  const [playerData, setPlayerData] = useState({});

  const [activeBtn, setActiveBtn] = useState("");

  const [modalVisible, setModalVisible] = useState(false);
  const [roundModalVisible, setRoundModalVisible] = useState(false);

  const isOnline = useOnlineStatus();

  useEffect(() => {
    dispatch(getExistingRound(roundName));
    dispatch(setHistory({
      name: roundName,
      created: Date.now()
    }));
  }, [dispatch, roundName]);

  useEffect(() => {
    //fetch any data that has changed when the browser is back in focus of user
    document.addEventListener("visibilitychange", function() {
      if (!document.hidden){
        dispatch(getExistingRound(roundName));
      }
    });

    //if we're on any iOS browser do some data syncing.
    if(navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/)) {
      if(!isOnline) {
        //save some syncing of data here, but how?
      }
    }

    socket.emit('round:join', roundName);
    socket.on('round:scored', (data) => {
      if(data.type === 'inc') {
        dispatch(setPlayerIncScore({
          id: data.id,
          playerId: data.playerId,
          holeId: data.holeId,
          index: data.index,
        }));
      } else if (data.type === 'dec') {
        dispatch(setPlayerDecScore({
          id: data.id,
          playerId: data.playerId,
          holeId: data.holeId,
          index: data.index
        }));
      } else if (data.type === 'clear') {
        dispatch(setPlayerClearScore({
          id: data.id,
          playerId: data.playerId,
          holeId: data.holeId,
          index: data.index
        }));
      }
    });

    return () => {
      socket.off('round:scored');
  };

  },[dispatch, roundName, isOnline]);


  const prevChunk = () => {
    let index = chunkIndex;
    setChunkIndex(--index);

  }
  const nextChunk = () => {
    let index = chunkIndex;
    setChunkIndex(++index);
  }

  const onHandleShowControls = (playerId, holeId, index) => {
    setIsControlsVisible(true);
    setPlayerData({
      playerId: playerId,
      holeId: holeId,
      index: index
    })
  }

  const onHandleDecrementScore = () => {
    dispatch(setPlayerDecScore({
      id: roundName,
      playerId: playerData.playerId,
      holeId: playerData.holeId,
      index: playerData.index,
      roundName: roundName,
      type: 'dec'
    }));
  }

  const onHandleIncrementScore = () => {
    dispatch(setPlayerIncScore({
      id: roundName,
      playerId: playerData.playerId,
      holeId: playerData.holeId,
      index: playerData.index,
      roundName: roundName,
      type: 'inc'
    }));
  }

  const onHandleClearScore = () => {
    dispatch(setPlayerClearScore({
      id: id,
      playerId: playerData.playerId,
      holeId: playerData.holeId,
      index: playerData.index,
      roundName: roundName,
      type: 'clear',
    }));
  }

  const handleActiveToggleBtn = (id) => {
    setActiveBtn(id);
  }

  const onHandleModalVisible = (bool) => {
    setIsControlsVisible(false);
    setModalVisible(bool);
  }

  const onHandleRoundModalVisible = (bool) => {
    setRoundModalVisible(bool);
  }

  if(isRoundLoading) {
    return <div style={{"color": "white"}}>Loading match data...</div>;
  }
  

  return (
    <>
      {modalVisible && <OptionsModal 
        players={players} 
        round={roundName}
        roundHoles={holes}
        modalVisible={modalVisible}
        handleModalVisible={onHandleModalVisible} />}

      {roundModalVisible && <EndRoundModal
        modalVisible={roundModalVisible}
        handleRoundModalVisible={onHandleRoundModalVisible} />}


      {isControlsVisible && !roundEnded && <div className="scorecard__modal bump">
        <div className="scorecard__modal-inner">
          <FontAwesomeIcon size="lg" onClick={() => onHandleClearScore() } icon={faRepeat} className="modal__btn" />
          <FontAwesomeIcon size="lg" onClick={() => onHandleDecrementScore() } icon={faAngleLeft} className="modal__btn" />
          <span></span>
          <FontAwesomeIcon size="lg" onClick={() => onHandleIncrementScore() } icon={faAngleRight} className="modal__btn" />
          <FontAwesomeIcon size="lg" onClick={() => setIsControlsVisible(false)} icon={faTimes} className="modal__btn" />
        </div>
      </div>}

      <div className="round__content-outer">
        <Header 
          round={roundName} 
          handleModalVisible={onHandleModalVisible} 
          handleRoundModalVisible={onHandleRoundModalVisible} />
        <ul className="scorecard__player-name">
          <li className="scorecard_hole">Hole</li>
          <li className="scorecard__players">

            {players?.length > 1 && <button onClick={prevChunk} disabled={chunkIndex === 0}>
            <FontAwesomeIcon icon={faAngleLeft} />
            </button>}
            {players?.length > 1 && chunkIndex+1}
            {players?.length > 1 && <button onClick={nextChunk} disabled={players.length === chunkIndex+1}>
              <FontAwesomeIcon icon={faAngleRight} />
            </button>}
          
            {!isRoundLoading && <PlayerName names={players[chunkIndex]} />}
          </li> 
        </ul>
        <div className="round__content-wrap">
          <ul className="scorecard">
            {holes && holes?.map((hole, index) => {
              return (
                <li key={hole.id} className="scorecard__hole">
                  <strong>{index+1}</strong>
                  <Players 
                    key={hole.id} 
                    players={hole.players[chunkIndex]} 
                    index={chunkIndex} hole={hole}
                    toggleActiveBtn={handleActiveToggleBtn}
                    activeBtn={activeBtn}
                    onHandleShowControls={onHandleShowControls} />
                </li>
              );
            })}
          </ul>
        </div>
        <div className="scorecard__player-total">
          <div className="scorecard__tag">Score Total</div>
          <div className="scorecard__totals">
            {!isRoundLoading && players && <PlayerScoreTotal players={players} index={chunkIndex} holes={holes} />}
          </div>
        </div>
      </div>
    </>
  )

}

export default Round;

