import { useDispatch, useSelector } from 'react-redux';
import React, { useState }  from 'react';
import {toast} from 'react-toastify';

import './options-modal.scss';
import { updatePlayerName, addPlayer, setPlayers } from '../../../store/reducers/cardReducer';
import { buildRoundCard, chunkPlayersForEveryHole } from '../../../store/reducers/roundReducer'
import { updatePlayerNamesRequest } from '../../../helpers/requests/updatePlayerNamesRequest';
import { chunk } from '../../../helpers/chunk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';


export const OptionsModal = (props) => {

  const dispatch = useDispatch();

  const [copied, setCopied] = useState(false);

  const cardStore = useSelector(state => state.card);
  const { players, id, selectedHole, maxPlayers } = cardStore;

  const roundStore = useSelector(state => state.round);
  const { chunks } = roundStore;

  const onHandleNameChange = (e) => {
    if (e.target.value.length >= 10) return;
    dispatch(updatePlayerName({
      value: e.target.value,
      id: e.target.name,
      pageId: e.target.getAttribute('data-page-id')
    }));
  }

  const handleCopy = () => {
    setCopied(true);
    navigator.clipboard.writeText(`https://matchd.app/${props.round}`);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }

  const saveAndClose = () => {
    let isEmpty = false;
    players.flat().forEach(player => {
      if(player.name === '') {
        isEmpty = true;
      }
    });

    if(isEmpty) {
      props.handleModalVisible(true);
      toast.error('Player names cannot be empty!');
      return;
    }

    updatePlayerNamesRequest({
      id: id,
      players: players.flat()
    }).then(() => {
      props.handleModalVisible(false);
    }).catch(error => {
      toast.error('Could not save.');
    })
  }

  const handleAddPlayer = () => {
    let flat = players.flat();
    if (flat.length === maxPlayers) return;

    flat.push({id: `${Date.now()}${flat.length+1}`, name: `P${flat.length+1}`, score: 0});

    let chunkedPlayers = chunk(flat, chunks);

    dispatch(buildRoundCard({
      holes: selectedHole,
      players: flat
    }));

    dispatch(addPlayer(chunkedPlayers));
    dispatch(chunkPlayersForEveryHole());
  }

  const handleDeletePlayer = (playerId) => {
    let flat = players.flat();

    if (flat.length === 1) return;

    let filtered = flat.filter(player => player.id !== playerId);

    let chunkedPlayers = chunk(filtered, chunks);

    dispatch(buildRoundCard({
      holes: selectedHole,
      players: chunkedPlayers
    }));

    dispatch(setPlayers(chunkedPlayers));
  }

  return (
    <div className="scorecard__options-backsplash">
      <div className="scorecard__options-panel-wrap">
        <div className={props.modalVisible ? 'scorecard__options-panel bump' : 'scorecard__options-panel'}>
          <h2>Share round</h2>
          <p>Copy this link and share with other players. They can edit and track their own scores.</p>          
          <div className="scorecard__copy-link">
            <span>https://matchd.app/{props.round}</span>
            <button onClick={handleCopy} title="Copy link" className={copied ? 'scorecard__copy-btn bump' : 'scorecard__copy-btn'}>{copied ? 'COPIED' : 'COPY'}</button>
          </div>

          <h2 className="scorecard__option-title">Edit player names</h2>
          <p>Change player nicknames below, max 4 characters.</p>
          <ul className="scorecard__options-players">
            {props.players && props.players.map((page, index) => {
              return page.map(player => {
                return <li key={player.id}>
                  <input 
                    type="text" 
                    name={player.id}
                    defaultValue={player.name} 
                    maxLength="4"
                    data-page-id={index} 
                    onChange={onHandleNameChange} />
                    <button onClick={() => handleDeletePlayer(player.id)} className="scorecard__delete-player" title="Delete player">
                      <FontAwesomeIcon icon={faTimes} size="sm" /> 
                    </button> 
                </li>;
              })
            })}
          </ul>
          {players.length < maxPlayers && <span onClick={handleAddPlayer} disabled={players.length >= 6} className="card__btn-start tiny outline">
            <FontAwesomeIcon icon={faPlus} size="sm" />
            Add Player
          </span>}
          <small> Max 6 per round. Min 1.</small>
          <div className="scorecard__options-footer">
            <button className="card__btn-start" onClick={saveAndClose} title="Save &amp; close">Save &amp; close</button>
          </div>
        </div>
      </div>
    </div>
  )
}