import { call, put, delay, select } from 'redux-saga/effects';
import { getExistingRoundRequest } from '../requests/getRoundRequest';
import { updateRoundRequest} from '../requests/updateRoundRequest';
import { getRoundCard, setRound, setIsRoundLoading, buildRoundCard, setHoles, getHoles, chunkPlayersForEveryHole, setRoundEnded } from '../../store/reducers/roundReducer';
import { setPlayers, changeHole, updateCardId } from '../../store/reducers/cardReducer';
import {chunk} from '../../helpers/chunk';
import { socket } from '../../helpers/socket';
import {generateSlug} from 'random-word-slugs';



export function* handleFetchRound() {
  yield put(setIsRoundLoading(true));
  try {
    //const res = yield call(getRoundRequest);
    const slug = generateSlug(3, { format: "kebab" });
    yield put(setRound(slug));
    yield put(setIsRoundLoading(false));
  } catch(err) {
    yield put(setIsRoundLoading(false));
    console.log(err);
  }
}

export function* handleFetchExistingRound({ payload }) {
  yield put(setIsRoundLoading(true));
  try {
    const res = yield call(getExistingRoundRequest, payload);
    const holes = JSON.parse(res.data.holes);
    yield put(changeHole({value: res.data.selectedHoles}));
    yield put(setRound(res.data.name));
    yield put(setRoundEnded(res.data.roundEnded));

    let chunked = yield select(getHoles);
    let chunkedPlayers = chunk(JSON.parse(res.data.players), chunked.payload.round.chunks);
    
    if(Object.keys(holes).length === 0) {
      yield put(buildRoundCard({
        holes: res.data.selectedHoles,
        players: JSON.parse(res.data.players)
      }));
      yield put(chunkPlayersForEveryHole());
      yield put(setPlayers(chunkedPlayers));
    } else {
      yield put(setHoles(JSON.parse(res.data.holes)));
    }
    yield put(setPlayers(chunkedPlayers));
    //yield put(setHoles(JSON.parse(res.data.holes)));

    yield put(updateCardId(res.data._id));
    yield put(setIsRoundLoading(false));
  } catch(err) {
    console.log(err);
    yield put(setIsRoundLoading(false));

  }
}

export function* debounceHandleUpdatePlayerScore({ payload }) {
  delay(300);
  yield handleUpdatePlayerScore({id: payload, holes: payload.holes});
}

export function* handleUpdatePlayerScore({payload}) {
  try {
    const res = yield select(getRoundCard);
    yield call(updateRoundRequest, {id: payload.id, holes: res.payload.round.holes});

    socket.emit('round:score', {
      id: payload.id,
      round: payload.roundName,
      type: payload.type,
      playerId: payload.playerId,
      holeId: payload.holeId,
      index: payload.index
    });

  } catch(err) {
    console.log(err);
  }
}