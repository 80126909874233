import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { socket } from '../../helpers/socket';
import PlayerScoreCount from '../ui/PlayerScoreCount'; 


const Players = (props) => {

  const { roundName } = useParams();

  const cardStore = useSelector(state => state.card);
  const { id } = cardStore;

  const onHandleIncrement = (playerId, holeId) => {
    socket.emit('round:score', {
      id: id,
      round: roundName,
      type: 'inc',
      playerId: playerId,
      holeId: holeId,
      index: props.index
    });
  }

  const onHandleDecrement = (playerId, holeId) => {
    socket.emit('round:score', {
      id: id,
      round: roundName,
      type: 'dec',
      playerId: playerId,
      holeId: holeId,
      index: props.index
    });
  }

  const handleShowControls = (playerId, holeId) => {
    props.onHandleShowControls(playerId, holeId, props.index)
  }

  return (
    <div className="card__game-btn-group">
      {props.players && props.players.map(player => {
        return (
          <PlayerScoreCount
            key={player.id}
            incScore={onHandleIncrement} 
            decScore={onHandleDecrement}
            showScoreControls={handleShowControls}
            toggleActiveBtn={props.toggleActiveBtn}
            playerId={player.id} 
            holeId={props.hole.id}
            score={player.score}
            activeBtn={props.activeBtn}
            btnIsPressed={props.btnIsPressed}
          />
        )
      })}
    </div>
  )
}

export default Players;