export const PlayerScoreTotal = (props) => {

  let players = props.players[props.index];
  

  const getPlayerTotal = (playerId) => {
    if(props.holes === undefined) return;
    let playerScores = [];
    let score = 0;
    
    props.holes.forEach(hole => {
      let player = hole.players[props.index].find(player => player.id === playerId);
      playerScores.push(player);
    });
    
    playerScores.forEach((player) => {
      if(!player) return false;
      score += player.score;
    });

    return score;
  }

  return (
    players.map(player => {
      return <span key={player.id}>{getPlayerTotal(player.id)}</span>
    })
  )
}