import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {useLocation} from 'react-router';
import { faGear, faGolfBallTee, faWifi } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/images/logo-full-white.svg';
import { useOnlineStatus } from '../../helpers/hooks/useOnlineStatus';

export const Header = (props) => {

  const isOnline = useOnlineStatus();

  let path = useLocation().pathname;
  const navigate = useNavigate();


  const roundStore = useSelector(state => state.round);
  const { roundEnded } = roundStore;

  const handleStartNewRound = () => {
    navigate('/');
  }
  
  return (
    <header className={path !== '/' ? 'round' : ''}>
      <nav>
        <a href="/" className="brand__logo">
          <img src={logo} alt="matchd.app" title="matchd.app" />
          <sup>BETA</sup>
        </a>
        {path !== '/' && !roundEnded && path !== '/g/history' && <div className="scorecard__options-control">
          <button title="End Round" onClick={() => props.handleRoundModalVisible(true)} className="card__btn-start light small" style={{marginRight: '0.5rem'}}>
            End Round
          </button>
          <button title="Configure Round" className="card__btn-start light small" onClick={() => props.handleModalVisible(true)}>
            <FontAwesomeIcon icon={faGear} size="lg" />
          </button>

          {!isOnline && <button className="card__btn-start light small offline" title="You are offline" style={{marginLeft: '1.2rem'}}>
            <FontAwesomeIcon icon={faWifi} size="sm" />
          </button>}

        </div>}

        {path !== '/' && roundEnded && <button className="card__btn-start light small" onClick={handleStartNewRound}>
        <FontAwesomeIcon icon={faGolfBallTee} size="sm" /> Start Round
        </button>}

        {!isOnline && path === '/' && <button className="card__btn-start light small offline" title="You are offline" style={{marginLeft: '1.2rem'}}>
            <FontAwesomeIcon icon={faWifi} size="sm" />
          </button>}
      </nav>
    </header>
  )
}