import axios from 'axios';

export const createRoundRequest = async (payload) => {
  try {
    return await axios.post(`/api/rounds`, payload);
  } catch(err) {
    //
  }
}

