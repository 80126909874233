import {useSelector} from 'react-redux';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {toast} from 'react-toastify';

import './options-modal.scss';
import { updateEndRoundRequest } from '../../../helpers/requests/updateEndRoundRequest';
import { setRoundEnded } from '../../../store/reducers/roundReducer';


export const EndRoundModal = (props) => {
  const navigate = useNavigate();

  const cardStore = useSelector(state => state.card);
  const { id } = cardStore;


  const close = () => {
    props.handleRoundModalVisible(false);
  }

  const endRound = () => {
    updateEndRoundRequest({
      id: id,
      roundEnded: true
    }).then(() => {
      setRoundEnded(true);
      props.handleRoundModalVisible(false);
      toast.success('Round ended.');
      navigate(`/`);
    }).catch(error => {
      toast.error('Could not save.');
    })
  }

  return (
    <div className="scorecard__options-backsplash">
      <div className="scorecard__options-panel-wrap">
        <div className={props.modalVisible ? 'scorecard__options-panel bump' : 'scorecard__options-panel'}>
          <h2>Are you sure?</h2>
          <p>Ending this round will lock the scorecard, and no further changes can be made.</p>

          <div className="scorecard__options-footer">
            <button className="card__btn-start outline" style={{marginRight: '0.75rem'}} onClick={close}>Close</button>
            <button className="card__btn-start" onClick={endRound}>End</button>
          </div>
        </div>
      </div>
    </div>
  )
}