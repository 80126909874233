import { useState } from 'react';
import styled from "styled-components";
import moment from 'moment';
import { useSelector, useDispatch } from "react-redux";
import Icon from 'react-eva-icons';

import { Header } from "../components/layout/Header";
import Navigation from "../components/layout/Navigation";
import { DeleteRoundModal } from '../components/layout/Modal/DeleteRoundModal';
import { getHistory } from '../store/reducers/historyReducer';
import { useEffect } from "react";
import "./pages.scss";

const Wrap = styled.div`
  display: flex;
  flex: 1 1 auto;
  background: white;
  overflow-y: scroll;
  padding: 1rem;
`;

const History = () => {

  const dispatch = useDispatch();
  const historyStore = useSelector((state) => state.history);
  const { history } = historyStore;

  const [copied, setCopied] = useState([]);
  const [showBin, setShowBin] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [gameData, setGameData] = useState("");

  useEffect( () => {
    dispatch(getHistory());
  }, [dispatch]);

  let newHistory = {};
  if(history?.length > 0) {
    newHistory = JSON.parse(JSON.stringify(history));
    newHistory.sort((a, b) => b.created - a.created);
  }

  const handleCopy = (name) => {
    setCopied((prev) => [...prev, name]);
    navigator.clipboard.writeText(`https://matchd.app/${name}`);
    setTimeout(() => {
      setCopied([]);
    }, 1000);
  }
  
  const onHandleModalVisible = (bool, gameData) => {
    setModalVisible(bool);
    setGameData(gameData)
  }

  return (
    <div className="matchd__content-wrap">
      {modalVisible && <DeleteRoundModal 
        modalVisible={modalVisible} 
        handleModalVisible={onHandleModalVisible}
        gameData={gameData}
      />}
      <Header />
      <Wrap>
        <article className="card__game-article history">

          <div className="history__list-title">
            <h2>Round History</h2>
            <button onClick={() => setShowBin(!showBin)}>
              <Icon name="trash-2-outline" fill="#000" style={{marginRight: '1rem'}} />Toggle Bin
            </button>  
          </div>
          <ul className="history__list">
            {history?.length > 0 && newHistory.map((game) => {
              return (
                <li key={game.name}>
                    <div className="scorecard__history-info">
                      <strong>{game.name}</strong>
                      <small>Played on: {moment(game.created).format('DD/MM/YY hh:mma')}</small>
                    </div>
                    <div className="scorecard__history-btn-stack">
                      <div className="scorecard__copy-link">
                        <button onClick={() => handleCopy(game.name)} title="Copy link" className={copied.find(name => name === game.name) ? 'scorecard__copy-btn bump' : 'scorecard__copy-btn'}>{copied.find(name => name === game.name) ? 'Copied!' : 'Share'}</button>
                      </div>
                      {!showBin && (
                        <a href={`/${game.name}`} className="history__link">
                          <Icon name="chevron-right-outline" fill="#000" />
                        </a>
                      )}

                      {showBin && (
                        <button onClick={() => onHandleModalVisible(!modalVisible, game.name)} className="history__link" style={{background: '#ffd0d0', color: 'white', border: '0'}}>
                          <Icon name="close-outline" fill="#cd3838" />
                        </button>
                      )}
                    </div>
                </li>
              );
            })}
            {history?.length === 0 && <li>
              <small style={{padding: '0.5rem'}}>You don't have any history yet.</small>
            </li>}
          </ul>
        </article>
      </Wrap>
      <Navigation />
    </div>
  );
};

export default History;
