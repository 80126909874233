const PlayerScoreCount = (props) => {

  const handleClick = (event) => {
    props.showScoreControls(props.playerId, props.holeId);
    props.toggleActiveBtn(event.target.id);
  }
  
  return (
    <>
      {/* <button className={btnClasses} onClick={() => props.decScore(props.playerId, props.holeId)}>-</button> */}
      <button 
        onClick={handleClick}
        id={`btn${props.playerId}${props.holeId}`}
        className={props.activeBtn === `btn${props.playerId}${props.holeId}` ? "card__game-btn active bump" : 'card__game-btn'}>
        {props.score === 0 ? `-` : props.score}
      </button>
      {/* <button className={btnClasses} onClick={() => props.incScore(props.playerId, props.holeId)}>&#x002B;</button> */}
    </>
  );
}

export default PlayerScoreCount;