import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  name: null,
  maxPlayers: 6,
  selectedHole: 9,
  players: [
    {id: 0, name: 'P1', score: 0}
  ],
  holes: [
    {name: 'nine', value: 9},
    {name: 'eighteen', value: 18},
  ]
}

const cardReducer = createSlice({
  name: 'card',
  initialState,
  reducers: {
    changeHole(state, action) {
      return {
        ...state,
        selectedHole: action.payload.value
      }
    },
    addPlayer(state, action) {
      if(state.players.length === state.maxPlayers) return;
      return {
      ...state,
        players: action.payload
      }
    },
    setPlayers(state, action) {
      return {
        ...state,
        players: action.payload
      }
    },
    clearPlayers(state, action) {
      return {
        ...state,
        players: []
      }
    },
    updateCardId(state, action) {
      return {
        ...state,
        id: action.payload
      }
    },
    incrementPlayers(state, action) {
      
      if(state.players.length === state.maxPlayers) return;
      return {
        ...state,
        players: state.players.concat([action.payload])
      }
    },
    decrementPlayers(state, action) {

      if(state.players.length === 1) return;

      let players = [...state.players]
      players.pop();

      return {
        ...state,
        players: players
      }
    },
    updatePlayerName(state, action) {
      let players = JSON.parse(JSON.stringify(state.players));
      let page = players[action.payload.pageId];
      let player = page.find(player => player.id === action.payload.id);
      player.name = action.payload.value;
      return {
        ...state,
        players: players
      };

    }
  }
});

export const { addPlayer, setPlayers, setChangeHole, changeHole, decrementPlayers, incrementPlayers, updateCardId, clearPlayers, updatePlayerName} = cardReducer.actions;

export default cardReducer;