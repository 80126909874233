import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  roundEnded: false,
  round: null,
  isRoundLoading: true,
  chunks: 4,
  holes: [],
}

const roundReducer = createSlice({
  name: 'round',
  initialState,
  reducers: {
    getRound(state, action) {
      return {
        ...state,
        round: state.round
      }
    },
    getExistingRound(state, action) {
      return {
        ...state,
        round: state.round
      }
    },
    setRound(state, action)  {
      return {
        ...state,
        round: action.payload
      }
    },
    setHoles(state, action) {
      return {
        ...state,
        holes: action.payload
      }
    },
    getHoles(state) {
      return {
        ...state,
        holes: state.holes,
      }
    },
    setIsRoundLoading(state, action) {
      return {
        ...state,
        isRoundLoading: action.payload
      }
    },
    setRoundEnded(state, action) {
      return {
        ...state,
        roundEnded: action.payload
      }
    },
    getIsRoundloading(state, action) {
      return {
        ...state,
        isRoundLoading: state.isRoundLoading
      }
    },
    buildRoundCard(state, action) {
      let holeTotal = action.payload.holes;
      let players = action.payload.players;
      let holes = [];
      for(let i = 0; i<holeTotal; i++) {
        holes.push({
          id: i,
          name: `Hole ${i+1}`,
          players: players,
          finished: false
        })
      }

      return {
        ...state,
        holes: holes
      }
    },
    getRoundCard(state, action) {
      return {
        rounds: state.holes
      }
    },
    setPlayerIncScore(state, action) {
      let hole = state.holes.find(hole => hole.id === action.payload.holeId);
      let player = hole.players[action.payload.index].find(player => player.id === action.payload.playerId);
      
      if(player.score === 30) return;

      player.score++;
      return state;
    },
    setPlayerDecScore(state, action) {
      let hole = state.holes.find(hole => hole.id === action.payload.holeId);
      let player = hole.players[action.payload.index].find(player => player.id === action.payload.playerId);

      if(player.score === 0) return;
      
      --player.score;

      return state;
    },
    setPlayerClearScore(state, action) {
      let hole = state.holes.find(hole => hole.id === action.payload.holeId);
      let player = hole.players[action.payload.index].find(player => player.id === action.payload.playerId);
      
      player.score = 0;
      return state;
    },

    chunkPlayersForEveryHole(state, action) {
      let holes = [];
      state.holes.forEach((hole, index) => {
        let holeCopy = {...hole};
        let holePlayers = {...hole.players}
          let players = chunk(holePlayers, state);
          holeCopy.players = players;
          holes.push(holeCopy);
      });
      return {
        ...state,
        holes: holes
      }
    }
  }
})

const chunk = (payload, state) => {
  return Object.values(payload).reduce((resultArray, item, index) => { 
    const chunkIndex = Math.floor(index/state.chunks);
    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }
    resultArray[chunkIndex].push(item)
    return resultArray
  }, []);
}

export const { 
  setRound,
  getRound,
  getHoles,
  setHoles,
  getExistingRound,
  setIsRoundLoading, 
  getIsRoundLoading, 
  buildRoundCard, 
  getRoundCard,
  setPlayerIncScore,
  setPlayerDecScore,
  setPlayerClearScore,
  chunkPlayersForEveryHole,
  setRoundEnded
} = roundReducer.actions;

export default roundReducer;