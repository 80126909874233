import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  history: [],
}

const historyReducer = createSlice({
  name: 'history',
  initialState,
  reducers: {
    getHistory(state, action) {
      let history = state.history;
      if ( JSON.parse(localStorage.getItem('rounds')) ) {
        history = JSON.parse(localStorage.getItem('rounds'));
      }
      return {
        ...state,
        history: history
      }
    },
    setHistory(state, action) {
      let localHistory = localStorage.getItem('rounds');
      if (localHistory) {
        let history = JSON.parse(localStorage.getItem('rounds'));
        let hasHistory = history.filter((h) => h.name === action.payload.name);

        if (hasHistory.length > 0) return false;
        
        history.unshift(action.payload);
        localStorage.setItem('rounds', JSON.stringify(history));

        return {
          ...state,
          history: JSON.parse(localStorage.getItem('rounds'))
        }

      } else {
        localStorage.setItem('rounds', JSON.stringify(state.history.concat(action.payload)));
        return {
          ...state,
          history: state.history.concat(action.payload)
        }
      }
    },
    replaceHistory(state, action) {

      localStorage.setItem('rounds', JSON.stringify(action.payload));
      return {
        ...state,
        history: action.payload
      }
    }

  }
});

export const { 
  getHistory,
  setHistory,
  replaceHistory
} = historyReducer.actions;

export default historyReducer;