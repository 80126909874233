import React from 'react';

const PlayerCount = (props) => {
  return (
    <>
      <button onClick={() => props.onDecPlayer()}>-</button>
      <span>{props.players.length}</span>
      <button onClick={() => props.onIncPlayer()}>&#x002B;</button>
    </>
  );
}

export default PlayerCount;