import { NavLink } from 'react-router-dom';
import Icon from "react-eva-icons";
import "./navigation.scss";

const Navigation = () => {
  return (
    <nav className="matchd__nav-wrap">
      <ul className="matchd__navigation">
        <li>
          <NavLink to="/" end className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
            <Icon
              name="play-circle-outline"
              size="xlarge" // small, medium, large, xlarge
              animation={{
                type: "pulse", // zoom, pulse, shake, flip
                hover: true,
                infinite: false,
              }}
            />
            <span>Start Round</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/g/history" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
            <Icon
              name="book-open-outline"
              size="xlarge" // small, medium, large, xlarge
              animation={{
                type: "pulse", // zoom, pulse, shake, flip
                hover: true,
                infinite: false,
              }}
            />
            <span>Round History</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
