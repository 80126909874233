import axios from 'axios';

export const updateEndRoundRequest = async (payload) => {
  try {
    return await axios.put(`/api/rounds/end/${payload.id}`, {
      roundEnded: payload.roundEnded
    });
  } catch(err) {
    console.log(err);
  }
}

