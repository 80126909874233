import { Header } from '../components/layout/Header';
import Navigation from '../components/layout/Navigation';
import Game from '../components/Game/Game';

const Create = () => {

  return (
    <div className="matchd__content-wrap">
      <Header />
      <Game />
      <Navigation />
    </div>
  );

}

export default Create;