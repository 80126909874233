import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import './assets/App.scss';
import 'react-toastify/dist/ReactToastify.css';

import configureStore from './store/store';
import Create from './pages/Create';
import History from './pages/History';
import Settings from './pages/Settings';
import Round from './components/Round/Round';
import { Error } from './pages/Error';
import { OnlineStatusProvider } from './helpers/hooks/useOnlineStatus';



const App = () => {

  return (
    <OnlineStatusProvider>
      <Provider store={configureStore}>
          <Router>
            <Routes>
              <Route path="/" element={<Create />} />
              <Route path="/:roundName" element={<Round />} />
              <Route path="/g/history" element={<History />} />
              <Route path="/g/settings" element={<Settings />} />
              <Route path="*" element={<Error />} />
            </Routes>
          </Router>
          <ToastContainer theme="dark" position="bottom-center" />
      </Provider>
    </OnlineStatusProvider>
  );
}

export default App;
