import axios from 'axios';

export const updatePlayerNamesRequest = async (payload) => {
  try {
    return await axios.put(`/api/rounds/name/${payload.id}`, {
      players: JSON.stringify(payload.players)
    });
  } catch(err) {
    console.log(err);
  }
}

