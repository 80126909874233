export const PlayerName = (props) => {
  return (
    props.names.map((name) => {
      if(name.name.length > 0) {
        return <span key={name.id}>{name.name}</span>
      } else {
        return <span key={name.id}>-</span>
      }
    })
  )
}