import { takeLatest, fork } from 'redux-saga/effects';
import { getRound, getExistingRound, setPlayerIncScore, setPlayerDecScore, setPlayerClearScore } from '../../store/reducers/roundReducer';
import { handleFetchRound, handleFetchExistingRound, handleUpdatePlayerScore } from '../handlers/roundHandler';

function* roundSaga() {
  yield takeLatest(getRound.type, handleFetchRound);
  yield takeLatest(getExistingRound.type, handleFetchExistingRound);
  yield takeLatest(setPlayerIncScore.type, handleUpdatePlayerScore);
  yield takeLatest(setPlayerDecScore.type, handleUpdatePlayerScore);
  yield takeLatest(setPlayerClearScore.type, handleUpdatePlayerScore);
}

export const roundSagas = [fork(roundSaga)];
