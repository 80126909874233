import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {toast} from 'react-toastify';

import { useOnlineStatus } from '../../helpers/hooks/useOnlineStatus';
import { changeHole, decrementPlayers, incrementPlayers, setPlayers, clearPlayers } from '../../store/reducers/cardReducer';
import { getRound, buildRoundCard, chunkPlayersForEveryHole } from '../../store/reducers/roundReducer';

import { createRoundRequest } from '../../helpers/requests/createRoundRequest';

import './game.scss';

import PlayerCount  from '../ui/PlayerCount'
import {chunk} from '../../helpers/chunk';

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100vh;
  background: white;
  padding: 1rem;
`;
const Wrap = styled.div`
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const Card = styled.div`
  display:  flex;
  flex-direction: column;
  background: white;
`;

const Game = () => {
  
  const navigate = useNavigate();

  //set up dispatch so its available inside () =>
  const dispatch = useDispatch();

  //card store
  const cardStore = useSelector(state => state.card);
  const { players, holes, selectedHole } = cardStore;

  //round store
  const roundStore = useSelector(state => state.round);
  const { round, isRoundLoading, chunks } = roundStore;

  const isOnline = useOnlineStatus();

  useEffect(() => {

    dispatch(clearPlayers());

    dispatch(incrementPlayers({
      id: `${Date.now()}0`,
      name: `P${1}`,
      score: 0
    }));

    dispatch(getRound());
  }, [dispatch, isOnline]);

  const onHandleDecPlayer = () => {
    dispatch(decrementPlayers())
  }

  const onIncPlayerHandler = () => {
    let player = players.length;
    dispatch(incrementPlayers({
      id: `${Date.now()}${player}`,
      name: `P${player+1}`,
      score: 0
    }));
  }

  const onHandleClient = async (e) => {
    e.preventDefault();

    //create round data for all players
    createRoundRequest({
      name: round,
      selectedHoles: selectedHole,
      players: JSON.stringify(players),
      holes: JSON.stringify({}),
      endRound: false,
    }).then((res) => {
      let chunkedPlayers = chunk(JSON.parse(res.data.players), chunks);
      dispatch(buildRoundCard({
        holes: res.data.selectedHoles,
        players: JSON.parse(res.data.players)
      }));
      dispatch(setPlayers(chunkedPlayers));
      dispatch(chunkPlayersForEveryHole());

      toast.success("Round created.");

      navigate(`/${round}`);
    }).catch(err => {
      if(!isOnline) {
        let chunkedPlayers = chunk(players, chunks);
        dispatch(buildRoundCard({
          holes: selectedHole,
          players: players
        }));
        dispatch(setPlayers(chunkedPlayers));
        dispatch(chunkPlayersForEveryHole());

        toast.success("Round created.");

        navigate(`/${round}`);
      }
    });
    
  }

  const onChangeHoleHandler = (hole) => {
    dispatch(changeHole(hole))
  }

  const onRoundRefreshHandler = () => {
    dispatch(getRound());
  }

  return (
    <>
      <OuterWrapper>
        <Wrap>
          <Card>
            <article className="card__game-article">
              <h2>Start a round</h2>
              <p>Choose how many holes you wish to play, and the number of players. You can share the scorecard with each player via the share button once the round has started.</p>
            </article>
            <ul className="card__game-controls">
              <li>
                Round Name
                <div className="card__game-round">
                  {round !== null && round}
                  {round === null && !isRoundLoading && "Coudn't pick a round name..."}
                  {round === null && isRoundLoading && "Picking round name..."}
                  <button onClick={onRoundRefreshHandler}>&#8635;</button>
                </div>
              </li>
              <li>
                Holes
                <div className="card__game-radio">
                  {holes.length > 0 && (
                    holes.map(hole => {
                      return (
                        <span key={hole.value}>
                          <input 
                            
                            type="radio" 
                            id={hole.name} 
                            name="holes" 
                            value={hole.value} 
                            checked={hole.value === selectedHole}
                            onChange={() =>onChangeHoleHandler(hole)}  
                          />
                          <label htmlFor={hole.name}>{hole.value}</label>
                        </span>
                      )
                    })
                  )}
                </div>
              </li>
              <li>
                Players
                <div className="card__game-inc">
                  <PlayerCount players={players} onDecPlayer={onHandleDecPlayer} onIncPlayer={onIncPlayerHandler}/>
                </div>
              </li>
            </ul>
            <button className="card__btn-start" disabled={players.length === 0} onClick={onHandleClient}>Start Round</button>
          </Card>
        </Wrap>
      </OuterWrapper>
    </>

  );
  

}

export default React.memo(Game); 