import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from "redux-saga";

import rootSaga from '../middleware/rootMiddleware';
import cardReducer from './reducers/cardReducer';
import roundReducer from './reducers/roundReducer';
import historyReducer from './reducers/historyReducer';

const saga = createSagaMiddleware();

const store = configureStore({
  reducer: {
    card: cardReducer.reducer,
    round: roundReducer.reducer,
    history: historyReducer.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(saga)
});


saga.run(rootSaga);


export default store;