import React from 'react';
import { useDispatch } from "react-redux";
import { replaceHistory } from '../../../store/reducers/historyReducer';

import './options-modal.scss';


export const DeleteRoundModal = (props) => {

  const dispatch = useDispatch();
  
  const close = () => {
    props.handleModalVisible(false);
  }
  
  const deleteHistoryItem = (name) => {
    const rounds = localStorage.getItem('rounds');
    const roundFilter = JSON.parse(rounds).filter(n => n.name !== name);
    dispatch(replaceHistory(roundFilter));
    props.handleModalVisible(false);
  }

  return (
    <div className="scorecard__options-backsplash">
      <div className="scorecard__options-panel-wrap">
        <div className={props.modalVisible ? 'scorecard__options-panel bump' : 'scorecard__options-panel'}>
          <h2>Are you sure?</h2>
          <p>Deleting this round will remove it from your device.</p>

          <div className="scorecard__options-footer">
            <button className="card__btn-start outline" style={{marginRight: '0.75rem'}} onClick={close}>No, Close</button>
            <button className="card__btn-start" onClick={() => deleteHistoryItem(props.gameData)}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  )
}